

































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import ProductCategory from "@/models/ProductCategory";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductCategoryService from "@/services/ProductCategoryService";
import LangModule from "@/store/LangModule";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";

@Component({
	filters: {
		filterId(list: ProductCategory[], productCategoryId: number) {
			return list.filter(pc => pc.id != productCategoryId)
		}
	}
})
export default class ProductCategoriesView extends Vue {

	lang: any = getModule(LangModule).lang
	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	@Ref() readonly form!: HTMLFormElement
	get productCategories() { return this.categoryTreeviewModule.productCategories }
	categories = []
	page: number = 1
	pageCount: number = 0
	loading: boolean = false
	search: string = ""
	productCategory: ProductCategory = new ProductCategory()
	open = []
	caseSensitive:boolean = false
	headers = [
		{text: "Id", value: "id", width: "120px", align: "center"},
		{text: this.lang.reference, value: "code", width: "200px", align: "center"},
		{text: this.lang.name, value: "name", width: "200px", align: "center"},
		{text: this.lang.category, value: "parent.name", width: "200px", align: "center"},
	]
	dialog: boolean = false
	referenceRule = [(v: any) => v ? true : "La referencia es requerida"]
	openAll: boolean = false


	created() {
		this.refresh()
	}

	refresh() {
		ProductCategoryService.getProductCategories(this, this.categories)
	}

	rowClick(productCategory: ProductCategory) {
		this.redirectTo(productCategory)
	}

	redirectTo(productCategory: ProductCategory) {
		this.$router.push({path: "/product-categories/" + productCategory.id})
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.productCategory = new ProductCategory()
	}

	createProductCategory() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
					this.lang.warning, this.lang.createProductCategoryConfirmation,
					() => ProductCategoryService.postProductCategory(this, this.productCategory)
			))
		}
	}

	@Watch("search")
	onSearchChanged() {
		this.openAll = true
	}
}
